.chip{
    border-radius: 5px;
    display: inline-block;
    font-size: 14pt;
    text-transform: uppercase;
    width: 50px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
}

.chip-blue{
    background-color: rgba(0, 0, 255, 0.5);
    color: white
}

.chip-green{
    background-color: rgba(0, 255, 0, 0.5);
    color: white
}

.chip-red{
    background-color: rgba(255, 0, 0, 0.5);
    color: white
}

.chip-yellow{
    background-color: rgba(255, 255, 0, 0.5);
    color: white
}

.chip-blank{
    height: 33px
}