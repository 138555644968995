.LoginScreen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #000;
}

.LoginBox{
    background-color: #3f3f3f;
    padding: 12px;
    border-radius: 8px;
}

.LoginBox > .submitButton{
    margin-top: 12px;
    display: flex;
    justify-content: center;
}