.AllTaskTitle{
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 6px;
}
.AllTaskTitle .Title{
    font-weight: bold;
    color:aqua;
}
.AllTaskTitle>div{
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 24px;
}
.AllTaskSubTask{
    font-weight: bold;
}

.AllTaskSubSub{
    padding-top: 3px;
    padding-bottom: 3px;
}