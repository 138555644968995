.TextBarHabit{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TopHabit{
    background-color: rgba(255, 193, 7, 0.5);
    color: white;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    position: relative; /* This is important for the absolute .TopHabit::before */
}

.WhiteTheme .TopHabit{
    background-color: rgba(158, 108, 0);
    color: white;
}


.TopHabit::after{
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer2 4s infinite;
}

@keyframes shimmer2 {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(200%);
    }
}


.DetailsHabits{
    border-left: 1px rgba(255, 193, 7, 0.5) solid;
    border-right: 1px rgba(255, 193, 7, 0.5) solid;
    border-bottom: 1px rgba(255, 193, 7, 0.5) solid;
    padding: 2px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    position: relative;
    width: calc(100%);
    z-index: 1;
    text-align: left;
}