.CalendarBarRounded{
    width: 100%;
    height: 30px;
}

.CalendarBarHidden{
    display: none;
}

.TextBar{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: rgba(14, 0, 90, 0.5);
    color: #FFF;
    height: 30px;
    align-items: center;
}

.WhiteTheme .TextBar{
    background-color: rgba(0, 61, 203);
    color: #fff;
}

.TextBarImminent{
    background-color: rgba(175, 122, 0, 0.5);
    color: white;
    height: 30px;
    align-items: center;
}   

.WhiteTheme .TextBarImminent{
    background-color: rgb(255, 179, 0.7);
    color: #000;
}

.TextBarReady{
    display: flex;
    flex-direction: row;
    width: 100%;
    color: gold;
    height: 30px;
    align-items: center;
}

.TextBarActive{
    background-color: rgba(180, 0, 0, 0.5);
    color: white;
    height: 30px;
    align-items: center;
}

.WhiteTheme .TextBarActive{
    background-color: rgba(255, 0, 0);
    color: #fff;
}

.TextBarNow{
    display: flex;
    flex-direction: row;
    width: 100%;
    color: gold;
    height: 30px;
    align-items: center;
}

.AppRootYellow .TextBarReady{
    color: rgb(251, 255, 0);
}

.WhiteTheme .TextBarReady{
    color: rgb(0, 0, 0);
}

.Text{
    font-size: 11pt;
    flex: 1
}

.Left{
    flex: 0 0 100px;
    text-align: right;
    margin-right: 3px;
}

.Right{
    flex: 1 1 auto;

}

.Clock{
    flex: 1 0 auto;
    text-align: right;
    margin-right: 10px;
}

.NoEvent{
    padding-left: 12px;
}