.TodoEdit{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TodoEdit_wrapper_inner{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding-right: 16px;
}

.TodoEdit_wrapper Button.btn-dark{
    background-color: rgba(0, 0, 0, 0.25);
}

.TodoEdit_wrapper Button.btn-dark:hover{
    background-color: rgba(255, 255, 255, 0.25);
}

.TodoEdit_wrapper Button.btn-danger{
    background-color: rgba(255, 0, 0, 0.25);
}

.TodoEdit input{
    background-color: transparent;
    color: white;
    border: solid 1px rgb(117, 117, 117);
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(100% + 8px);
}

.TodoEdit input.content{
    font-size: 20px;
    font-weight: bold;
    color: gold;
}

.TodoEdit_caption{
    margin-left: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: cyan;
}

.TodoEdit_caption.warn{
    color: red;
}

.TodoEdit_actionBar{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-bottom: 16px;
    justify-content: stretch;
}

.TodoEdit_actionBar button{
    flex: 1 0 auto;
    margin-right: 8px;
    width: 100%;
    margin-bottom: 8px;
}
