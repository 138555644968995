.advisory{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.advisory .advisoryCell{
    display: flex;
    flex: 1 1 33%;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
}

.advisory .advisoryText{
    text-align: center;
    font-size: 1.2em;
}

.advisoryAction {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}