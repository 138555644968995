.ChecklistApp{
    margin-left: 8px;
    margin-right: 8px;
}

.ChecklistApp Button.btn-primary{
    background-color: rgba(45, 119, 255, 0.5);
    border: none;
}

.ChecklistApp button.dropdown-toggle{
    background-color: rgba(40, 167, 69, 0.5);
    border: none;
}

.ChecklistTitle{
    color: cyan;
    font-size: 14pt;
}

.ChecklistApp .btn{
    text-transform: uppercase;
}

.ChecklistItem{
    display: flex;
    flex-direction: row;
}

.ChecklistItem .item{
    flex: 0 0 auto;
}

.ChecklistItem .checkeditem{
    flex: 0 0 auto;
    color: lightgray;
    text-decoration: line-through;
}

.ChecklistItem .dots{
    flex: 1 1 auto;
    letter-spacing: 1em;
    overflow: hidden;
    font-family: sans-serif;
}


.ChecklistItem .checkedaction{
    flex: 0 0 auto;
    color: lightgray;
    text-decoration: line-through;
}

.ChecklistItem .action{
    flex: 0 0 auto;
    color: lightskyblue;
}

.ChecklistItem .check{
    margin-left: 4px;
    
}

.checklistComment {
    padding: 8px;
    background-color: rgba(110, 110, 110, 0.35);
    flex: 1;
    border-radius: 8px;
    align-items: center;
    margin-top: 16px;
}