.QuickDateRangePicker{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.QuickDateRangePicker .button-grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.QuickDateRangePicker .button-cell{
    flex: 1 0 auto;
}

.DataControls{
    margin-top: 4px;
}

.CalendarDivvv{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    color: #000;
    padding: 12px;
    border-radius: 4px;
    margin-top: 12px;
}

.StatsDivvv{
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff43;
    padding: 12px;
    border-radius: 4px;
    margin-top: 12px;
}

.TotalMinutes{
    margin-top: 12px;
    font-weight: bold;
    color: cyan;
}

.GroupedByDateDivvv{
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff43;
    padding: 12px;
    border-radius: 4px;
}

.friendly-date{
    font-size: 12px;
    color: #666;
}