.EditAnyEdit {
    margin: 12px;
    padding: 12px;
    background-color: rgba(38, 38, 38, 0.5);
    margin-bottom: 12px;
    border-radius: 8px;
    border: solid 1px cyan;
}

.EditAnySubSubTask{
    margin: 12px;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-left: 8px;
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 8px;
}

.EditAnyEdit .SubTaskTitleEdit input {
    color: cyan;
    font-size: 18pt;
}

.EditAnyEdit input{
    background-color: transparent;
    color: white;
    border: solid 1px rgb(117, 117, 117);
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(100% + 8px);
}

.EditAnyTemplate{
    margin: 10px;
    padding: 12px;
    background-color: rgba(38, 38, 38, 0.5);
    margin-bottom: 12px;
    border-radius: 8px;
    border: solid 1px cyan;
}

.EditAnyTemplate input{
    background-color: transparent;
    color: white;
    border: solid 1px rgb(117, 117, 117);
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(100% - 8px);
}

.EditAnyBody{
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    padding-top: 90px;
}

.EditAnyButtonControls{
    display: flex;
    flex-direction: row;
    margin: 6px;
    flex-wrap: wrap;
}

.EditAnyButtonControls Button{
    margin-bottom: 6px;
}

.NewStamp {
    margin-top: 24px;
    border: solid 1px cyan;
    padding: 12px;
    background-color: rgba(38, 38, 38, 0.5);
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;
}

.NewStamp .NewStampField{
    margin-bottom: 12px;
}

.NewStamp .NewStampField input[type="text"] {
    background-color: transparent;
    color: white;
    border: solid 1px rgb(117, 117, 117);
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(100% - 8px);
}

.EditAnySns{
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: rgba(38, 38, 38, 0.5);
    padding: 12px;
    border-radius: 8px;
    border: solid 1px cyan;
}

.EditAnySticky{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(40, 40, 40, 0.8);
    backdrop-filter: blur(8px);
    border-bottom: solid 1px rgb(80, 80, 80);
    flex-direction: row;
}

.EditAnyEdit .Field>div:first-child{
    flex: 1 0 50%;
}

.EditAnyEdit .Field>div:last-child{
    flex: 1 0 50%;
}

.EditAnyTemplate .btn{
    margin-bottom: 3px;
}

.TaskCommandButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.TaskCommandButtons .btn{
    margin-bottom: 3px;
}

.LoadingIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    position: fixed;
    top: calc(50vh - 30px);
    left: calc(50vw - 30px);
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 8px;
    z-index: 1000;
}