@font-face {
  font-family: 'Harmony';
  src: local('Harmony'), url(./fonts/HarmonyOS_Sans_Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Harmony';
  font-weight: bold;
  src: local('Harmony'), url(./fonts/HarmonyOS_Sans_Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'ProductSans';
  src: local('ProductSans'), url('./fonts/Product Sans Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'ProductSans';
  font-weight: bold;
  src: local('ProductSans'), url('./fonts/Product Sans Bold.ttf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Harmony', sans-serif;
  /* font-family: 'ProductSans', sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

