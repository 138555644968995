.dwp-wrapper{
    position: relative;
    height: 30px;
    color: white;
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
}

.dwp-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 30px;
    width: 100%;
    z-index: 25;
    overflow: hidden;
    white-space: nowrap;
}

.dwp-progressbar{
    position: absolute;
    display: flex;
    height: 30px;
    width: 100%;
    border: solid 1px rgb(69, 69, 69);
    border-radius: 5px;
}

.dwp-active{
    flex: 0 0 0;
    border-radius: 5px;
    z-index: 20
}

.dwp-active-blue{
    background-color: rgba(0, 158, 158, 0.5);
}

.AppRootYellow .dwp-active-blue{
    background-color: rgba(126, 126, 126, 0.5);
}

.dwp-border-blue{
    border: solid 1px rgba(0, 214, 214, 0.5);
}

.AppRootYellow .dwp-border-blue{
    border: solid 1px rgba(204, 204, 204, 0.5);
}

.AppRootYellow .dwp-desc-border-blue{
    border-left: solid 1px rgba(204, 204, 204, 0.5);
    border-right: solid 1px rgba(204, 204, 204, 0.5);
    border-bottom: solid 1px rgba(204, 204, 204, 0.5);
    border-top: 0;
}

.dwp-active-red{
    background-color: rgba(220, 53, 69, 0.5);
}

.AppRootYellow .dwp-active-red{
    background-color: rgba(158, 0, 0, 0.4);
}

.dwp-border-red{
    border: solid 1px #dc3545;
}

.dwp-desc-border-red{
    border-left: solid 1px #dc3545;
    border-right: solid 1px #dc3545;
    border-bottom: solid 1px #dc3545;
    border-top: 0;
}

.AppRootYellow .dwp-border-red{
    border: solid 1px rgba(222, 74, 89, 0.65);
}

.AppRootYellow .dwp-desc-border-red{
    border-left: solid 1px rgba(222, 74, 89, 0.65);
    border-right: solid 1px rgba(222, 74, 89, 0.65);
    border-bottom: solid 1px rgba(222, 74, 89, 0.65);
    border-top: 0;
}

.dwp-desc-border-blue{
    border-left: solid 1px rgba(0, 214, 214, 0.5);
    border-right: solid 1px rgba(0, 214, 214, 0.5);
    border-bottom: solid 1px rgba(0, 214, 214, 0.5);
    border-top: 0;
}

.AppRootYellow .dwp-border-blue{
    border: solid 1px rgba(204, 204, 204, 0.5);
}

.dwp-active-yellow{
    background-color: rgba(116, 99, 0, 0.5);
}

.AppRootYellow .dwp-active-yellow{
    background-color: rgba(181, 153, 0, 0.5);
}

.dwp-border-yellow{
    border: solid 1px rgb(164, 139, 0);
}

.dwp-desc-border-yellow{
    border-left: solid 1px rgb(164, 139, 0);
    border-right: solid 1px rgb(164, 139, 0);
    border-bottom: solid 1px rgb(164, 139, 0);
    border-top: 0;
}

.WhiteTheme .dwp-active{
    background-color: rgba(45, 119, 255, 1);
}

.WhiteTheme .dwp-progressbar{
    border: solid 1px rgba(45, 119, 255, 1);
}

.dwp-inactive{
    flex: 1;
    
    border-radius: 5px;
    z-index: 22
}

.WhiteTheme .dwp-inner>span{
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 20px;
}

.DetailsCalWithoutMargin{
    border-top: 0;
    padding: 2px;
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 5px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 16px);
}

.WhiteTheme .DetailsCalWithoutMargin{
    border: solid 1px #b1caff;
    border-top: 0;
}