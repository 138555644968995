.TextBar{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.TextBarNoti{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.TextBarNotiCentered{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.Square{
    width: 120px;
    border: 1px gold solid;
    height: 100px;
    margin: 12px;
    text-align: center;
    padding: 2px;
    border-radius: 10px;
    
}

.TitleCal{
    background-color: violet;
    width: 100%;
    padding: 8px;
    margin: 8px;
    color: black;
    border-radius: 10px;
    margin-top: 12px;
}

.TitleCalExpired{
    background-color: darkblue;
    border: solid 1px violet;
    width: 100%;
    padding: 8px;
    margin: 8px;
    color: white;
    border-radius: 10px;
    margin-top: 12px;
    z-index: 10;
}

.DetailsCal{
    border: 1px violet solid;
    padding: 2px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.BlinkingNotification {
    -webkit-animation: blink 5s infinite both;
            animation: blink 5s infinite both;
}

.BlinkingBorderNotification{
    -webkit-animation: blinkborder 5s infinite both;
            animation: blinkborder 5s infinite both;
}

.BlinkingProgressBarColor{
    -webkit-animation: blinkprogressbarcolor 5s infinite both;
            animation: blinkprogressbarcolor 5s infinite both;
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

@-webkit-keyframes blinkborder {
    0%,
    50%,
    100% {
      border: 1px solid violet;
    }
    25%,
    75% {
      border: 1px solid black;
    }
  }
  @keyframes blinkborder {
      0%,
      50%,
      100% {
          border: 1px solid violet;
      }
      25%,
      75% {
          border: 1px solid black;
      }
  }

@-webkit-keyframes blinkprogressbarcolor {
0%,
50%,
100% {
    background-color: violet;
}
25%,
75% {
    background-color: blue;
}
}

@keyframes blinkprogressbarcolor {
    0%,
    50%,
    100% {
        background-color: violet;
    }
    25%,
    75% {
        background-color: blue;
    }
}