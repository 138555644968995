.keypad{
    display: flex;
    flex-direction: column;
}

.keypad-row{
    display: flex;
    flex-direction: row;
}

.keypad-row>div{
    display: flex;
    flex: 1;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 5px;
    text-align: center;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    border-radius: 5px;
    webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;      /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Safari */
}

.keypad-row>div:hover{
    background-color: rgba(255, 255, 255, 0.3);
}

.keypad-row>div:active{
    background-color: rgba(255, 255, 255, 0.5);
}

.keypad-digits{
    display: flex;
    position: relative;
    flex: 1 0 auto;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    text-align: center;
    font-size: 20px;
    justify-content: center;
    height: 60px;
    align-items: center;
}