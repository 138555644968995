.SubTaskEdit {
    margin: 6px;
    padding: 12px;
    background-color: rgba(38, 38, 38, 0.5);
    margin-bottom: 12px;
    border-radius: 8px;
    border: solid 1px cyan;
}

.SubTaskEdit .btn-outline-primary{
    color: white;
    border-color: white;
}

.BodyEdit .btn{
    text-transform: uppercase;
}

.BodyEdit .SyncWarning{
    margin-top: 12px;
    padding: 8px;
    border-radius: 8px;
    border: solid 1px red;
    background: repeating-linear-gradient(
        45deg,
        #660000 0px,
        #660000 10px,
        #810000 10px,
        #810000 20px
    );
    margin-left: 6px;
    margin-right: 6px;
}

.BodyEdit .SyncWarning div:first-child{
    font-size: 18pt;
    color: yellow;
}



.BodyEdit input{
    background-color: transparent;
    color: white;
    border: solid 1px rgb(117, 117, 117);
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(100% + 8px);
}

.BodyEdit .SubTaskTitleEdit input {
    color: cyan;
    font-size: 18pt;
}

.SubTaskGrayedOut{
    background-color: rgba(73, 0, 0, 0.5);
    border: solid 1px red;
}

.SubTaskGrayedOut .SubSubTaskEdit{
    background-color: rgba(93, 0, 0, 0.5);
}

.SubTaskSelect{
    background-color: rgba(0, 76, 70, 0.5);
    border: solid 1px rgb(0, 255, 208);
}

.SubSubTaskEdit{
    margin: 12px;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-left: 8px;
    background-color: rgba(64, 64, 64, 0.5);
    border-radius: 8px;
}

.SubTaskSelect .SubSubTaskEdit{
    background-color: rgba(0, 97, 89, 0.5);
}

.SubSubTaskEditGrayedOut{margin: 12px;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-right: 16px;
    padding-left: 8px;
    border-radius: 8px;
    background-color: rgba(193, 0, 0, 0.5);
    border: solid 1px red;
}

.StickyEdit{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(40, 40, 40, 0.8);
    backdrop-filter: blur(8px);
    border-bottom: solid 1px rgb(80, 80, 80);
}

.StickyEdit .btn{
    text-transform: uppercase;
}

.StickyEdit Button{
    margin-left: 1px;
    margin-right: 1px;
}

.BodyEdit{
    padding-top: 80px;
    margin: auto;
    max-width: 800px;
    width: 100%;
}

.BodyEdit Button{
    margin-left: 1px;
    margin-right: 1px;
}

.Field {
    display: flex;
    flex-direction: row;
}

.Field > div:first-child {
    display: flex;
    align-items: center;
}

.Field > div:last-child {
    flex: 1;
    flex-direction: row;
    display: flex;
}

.Field input {
    width: 100%;
}

.TaskSelector{
    margin-left: 10px;
    margin-right: 10px;
}

.AllTasksSummary{
    position: fixed;
    top: 80px;
    left: 10px;
    min-height: 240px;
    width: 200px;
    overflow-y: scroll;
    padding: 10px;
    
    border-radius: 8px;

    background: rgba(0, 76, 70, 0.5);
    border: solid 1px rgb(0, 255, 208);

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.AllTasksSummary::-webkit-scrollbar {
    display: none;
}

.AllTasksSummaryBlock{
    display: flex;
    flex-direction: row;
}

.AllTasksSummary .AllTasksSummaryHeader{
    color: cyan;
    padding-bottom: 12px;
}

.AllTasksSummaryBlock .AllTasksSummaryTitle{
    flex: 0 0 auto;
}

.AllTasksSummaryBlock .AllTasksSummaryBlank {
    flex: 1 0 auto;
}

.AllTasksSummaryBlock .AllTasksSummaryTime{
    flex: 0 0 auto;
}

.ViewOptions{
    display: flex;
    flex-direction: row;
    padding-left: 4px;
    margin-left: 14px;
    margin-right: 14px;
}

.TemplateControls{
    display: flex;
    flex-direction: column;
    background-color: rgba(38, 38, 38, 0.5);
    margin-top: 12px;
    padding: 12px;
    border-radius: 5px;
    border: solid 1px cyan;
    margin-left: 6px;
    margin-right: 6px;
}

.TemplateControls .TemplateControlButtons{
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}

.TemplateControls input{
    width: 100%;
}

.SubSubTaskEdit .btn{
    width: 100%;
    margin-bottom: 2px;
}

.SubtaskActionButtons .btn{
    width: 100%;
    margin-bottom: 2px;
}

.BottomButtons .btn{
    width: 100%;
    margin-bottom: 2px;
}

.SubSubTaskEdit .SubSubButtonControls{
    margin: 0 auto;
}

.SubSubTaskEditGrayedOut .SubSubButtonControls{
    margin: 0 auto;
}

.SubSubTaskEdit .SubSubButtonControls>div{
    padding-left: 1px;
    padding-right: 1px;
}

.SubSubTaskEditGrayedOut .SubSubButtonControls>div{
    padding-left: 1px;
    padding-right: 1px;
}

.SubSubTaskEditGrayedOut .SubSubButtonControls .btn{
    width: 100%;
    margin-top: 2px;
}