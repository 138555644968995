.CalendarDayViewEvent {
    position: absolute;
    border-radius: 5px;
    padding: 5px; /* Remove single quotes */
    box-sizing: border-box; /* Remove single quotes */
    color: white; /* Remove single quotes */
    display: flex; /* Remove single quotes */
    justify-content: center; /* Remove single quotes */
    align-items: center; /* Remove single quotes */
    width: 100%; /* Remove single quotes */
    overflow: hidden; /* Remove single quotes */
    margin-left: 20px; /* Add 'px' unit */
    z-index: 2;
    backdrop-filter: blur(5px); /* Remove single quotes */
    -webkit-backdrop-filter: blur(5px); /* Remove single quotes */
}

.WhiteTheme .CalendarDayViewEvent {
    color: black; /* Remove single quotes */
}