.feedbackForm .question{
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    backdrop-filter: blur(5px);
}

.feedbackForm .question .question-text{
    margin-bottom: 5px;
}

.feedbackForm .question::last-child{
    margin-bottom: 0;
}

.feedbackSubmit{
    margin-left: 10px;
    margin-right: 10px;
}

.feedbackSubmit Button{
    margin-right: 10px;
}

.feedbackSubmit Button::last-child{
    margin-right: 0;
}

.feedbackTaskInfo {
    margin-left: 10px;
    margin-right: 10px;
    color: cyan;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14pt;
}