html, body, #root {
    height: 100%;
    color: white;
    /* font-family: 'ProductSans'; */
    font-family: 'Harmony';
    font-size: 11pt;
}

.AppRoot{
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./bg.jpg');
    background-size: cover;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-attachment: fixed; /* Keep the image fixed during scrolling */
    min-height: 100%;
    display: flex;
    flex-direction: column;
    webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;      /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Safari */
}

.AppRootDesktop{ /* Milder background for desktop experience */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./bg.jpg');
    background-size: cover;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-attachment: fixed; /* Keep the image fixed during scrolling */
    display: flex;
    flex-direction: column;
}

.AppRootYellow{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('./yellowbg.jpg');
    background-size: cover;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-attachment: fixed; /* Keep the image fixed during scrolling */
}

.AppRootRed{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./redbg.jpg');
    background-size: cover;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-attachment: fixed; /* Keep the image fixed during scrolling */
}

.WhiteTheme{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url('./bg-white.jpg');
    background-size: cover;
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-attachment: fixed; /* Keep the image fixed during scrolling */
    color: black;
}

.AppRootNoBg{
    background: rgb(0,0,0);
}

.AppRootInnerOverlay{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    backdrop-filter: blur(15px);
    min-height: 100vh;
}

.WhiteTheme .AppRootInnerOverlay{
    backdrop-filter: blur(15px);
}


.AppV{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.AppOverlay{
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
    justify-content: center;
}

.AppOverlayTodos{
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}

.OverlayLeftColumn{
    display: flex;
    flex-direction: column;
    flex: 2;
}

.OverlayRightColumn{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.ProcastTaskItem{
    padding: 10px;
    margin-bottom: 6px;
    flex-direction: column;
    text-align: left;
    background-color: rgba(68, 68, 68, 0.25);
    border-radius: 12px;
}

.WhiteTheme .ProcastTaskItem{
    background-color: rgba(255, 255, 255, 0.18);

}

.ProcastButtons Button.btn-dark{
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
}

.WhiteTheme .ProcastButtons Button.btn-dark{
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
}

.ProcastButtons Button.btn-dark:hover{
    background-color: rgba(0, 0, 0, 0.35);
}

.WhiteTheme .ProcastButtons Button.btn-dark:hover{
    background-color: rgba(255, 255, 255, 0.35);
    color: black;
}

.ProcastButtons Button.btn-primary{
    background-color: rgba(45, 119, 255, 0.15);
}

.ProcastButtons Button.btn-primary:hover{
    background-color: rgba(45, 119, 255, 0.35);
}

.OverlayFullWidth{
    width: 100%;
    max-width: 300px;
}

.CalendarBar{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
}

.App{
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    height: 100%;
}

.WhiteTheme{
    position: relative;
    color: #000
}

.Pomodoro{
    flex-direction: column;
    flex: 1.5;
    display: flex;
    padding-top: 30px;
}

.Timer{
    
    font-size: 24pt;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
}

.TimerF{
    font-size: 32pt;
    font-weight: bold;
    cursor: pointer;
}

.Timer .NumericFields{
    flex: 1 1 50%;
    cursor: pointer;
}

.MinHeightTimerLayout{
    min-height: 280px;
    display: flex;
    flex-direction: column;
}

.FixedHeightTimerLayoutR{
    height: 290px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.FixedHeightTimerLayoutR::-webkit-scrollbar{
    display: none;
}

.StandbyTimer{
    font-size: 12pt;
    display: flex;
    flex-direction: row;
}

.ClockSubTitle{
    font-size: 12pt;
    text-align: center;
}

.NoTaskWarning{
    color: rgba(255, 0, 0, 0.5);
    margin-top: 20px;
}

.WhiteTheme .NoTaskWarning{
    color: #dc3545;
}

.Tasks{
    flex-direction: column;
    flex: 1;
    display: flex;
    padding: 0px;
}

.Tasks .progress{
    height: 5px;
    margin-bottom: 5px;
    background-color: rgba(140, 140, 140, 0.3);
}

.WhiteTheme .Tasks .progress{
    background-color: #e0e0e0;
}

.TimerCommandButtons{
    margin-top: 20px;
    flex-direction: row;

}

.TimerCommandButtons > Button{
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.Label{
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    color: cyan
}

.AppRootYellow .Label{
    color: #fff;
}

.WhiteTheme .Label{
    color: #007982
}

.SubTaskHome{
    margin-bottom: 0px;
    flex-direction: column;
    text-align: left;
    cursor: pointer;
}

.SubTaskHomeRemaining{
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
}

.TimerHistoryEntry{
    margin: 12px;
    flex-direction: column;
    text-align: left;
    padding: 12px;
    border-radius: 12px;
    background-color: rgba(68, 68, 68, 0.25);
}

.WhiteTheme .TimerHistoryEntry{
    background-color: rgba(255, 255, 255, 0.1);
}

.TimerHistory .TimerHistoryTitle{
    color: cyan;
    font-weight: bold;
}

.WhiteTheme .TimerHistory .TimerHistoryTitle{
    color: #007982;
}

.SubTaskActive{
    margin-top: 6px;
    margin-bottom: 6px;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto;
}

.ActiveTaskList{
    flex: 1 0 auto;
}

.SubTaskActive::-webkit-scrollbar{
    display: none
}

.SubTaskHome .Title{
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: cyan;
}

.AppRootYellow .SubTaskHome .Title{
    color: white;
}

.WhiteTheme .SubTaskHome .Title{
    color: #007982
}

.SubTaskHome .progress-bar{
    background-color: cyan;
    border-radius: 3px;
}

.AppRootYellow .SubTaskHome .progress-bar{
    background-color: white;
}

.WhiteTheme .SubTaskHome .progress-bar{
    background-color: #007982;
}

.WhiteTheme .flagsColorWrapper{
    /* background-color: rgba(255, 255, 255, 0.27); */
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
}

.SubTaskHome .TitleLeft{
    font-weight: bold;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    color: cyan;
    margin-top: 6px;
    margin-bottom: 6px;
}

.WhiteTheme .SubTaskHome .TitleLeft{
    color: #007982
}

.ActiveSubTaskHome{
    background-color: rgba(0, 0, 0, 0.11);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* border-bottom: solid 1px rgba(64, 64, 64, 0.5); */
    padding: 12px;
    margin-bottom: 12px;
}

.AppRootYellow .ActiveSubTaskHome{
    background-color: rgba(255, 255, 255, 0.16);
}

.AppRootRed .ActiveSubTaskHome{
    background-color: rgba(255, 79, 79, 0.16);
}


.WhiteTheme .ActiveSubTaskHome{
    background-color: rgba(207, 110, 255, 0.25);
    border-left: none;
}
.ActiveSubTaskHome .Title{
    color: gold;
}

.SubTaskSelected{
    background-color: blue;
    color: white
}

.Title{
    font-weight: bold;
}

.TitleCalendarSideBar{
    text-align: left;
    font-weight: bold;
    color: cyan;
}

.CalendarSidebarEntry{
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
    margin-bottom: 12px;
    height: 48px;
}

.CalendarSidebarEntryFreeHeight{
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
    margin-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
}

.TitleRouge{
    color: #ff9410
}

.TimerHighlightStat{
    color: rgb(187, 247, 46)
}

.TimerHistory{
    flex-direction: row;
    flex: 1;
}

.TimerCommandButtonsRows{
    flex-direction: row;
    flex: 1;
    display: flex
}

.TimerCommandButtonsRows Button{
    display: flex;
    flex: 1;
    margin-left: 1px;
    margin-right: 1px;
}

.TimerCommandButtonsRows Button:first-child{
    margin-left: 0px;
}

.TimerCommandButtonsRows Button:last-child{
    margin-right: 0px;
}

.TimerControlPrimaries{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    overflow: hidden;
}

.TimerControlPrimaries Button{
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.TimerControlPrimaries Button:first-child{
    margin-left: 0px;
}

.TimerControlPrimaries Button:last-child{
    margin-right: 0px;
}

.CommandButtonsAux Button{
    margin-bottom: 2px;
}

.ScrollButtons{
    position: fixed;
    top: 0;
    right: 120px;
    z-index: 1000;
}

.ScrollButtonsAlign{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.ScrollButtons Button{
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.ButtonAlign{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10pt;
}

.ButtonGlyph{
    margin-right: 5px;
}

.TaskReminderBar{
    flex: 1;
    max-width: 306px;
}

.NotificationBar{
    flex: 1;
    justify-content: center;
}

.TimerDurationOptions{
    flex-direction: row;
    display: flex;
    padding-top: 4px; 
    padding-bottom: 4px;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
}

.TimerDurationOptions div{
    padding-left: 4px;
    padding-right: 4px;
    display: block;
    flex: 1;
    border-radius: 15px;
}

.DurationActiveAqua {
    background-color: rgba(0, 158, 158, 0.5);
    color: #fff;
}

.WhiteTheme .DurationActiveAqua{
    background-color: #007982;
    color: white;
}

.AppRootYellow .DurationActiveAqua{
    background-color: rgba(177, 181, 179, 0.5);
}

.DurationActiveYellow{
    background-color: rgba(255, 193, 7, 0.5);
    color: #fff;
}

.WhiteTheme .DurationActiveYellow{
    background-color: #ffca2c;
    color: black;
}

.DurationCountdown{
    background-color: rgba(220, 53, 69, 0.5);
    color: #fff;
}

.AppRootYellow .DurationCountdown{
    background-color: rgba(220, 53, 69, 0.5);
}

.WhiteTheme .DurationCountdown{
    background-color: #ab2633;
}

.VisualTimerProgressBar{
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
}

.VisualTimerProgressBar .progress{
    height: 7px;
    background-color: rgba(140, 140, 140, 0.3);
}

.WhiteTheme .VisualTimerProgressBar .progress{
    background-color: #e0e0e0;
}

.ActiveSubTaskHome .progress-bar{
    background-color: #edba01;
    border-radius: 3px;
}

.NotiDescription{
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
}

.highlightedSubtask{
    background-color: rgba(221, 188, 0, 0.8);
    color: #FFF;
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 3px;
    color: black;    
}

.AppRootYellow .highlightedSubtask{
    background-color: rgba(255, 255, 255, 0.6);
}

.WhiteTheme .highlightedSubtask{
    background-color: #007982;
    color: #fff;
}

.subtaskEntry{
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 12px;
}

.subtaskEntry .subtaskFlag{
    padding-right: 4px;
}

.highlightedSubtask .subtaskFlag{
    padding-right: 4px;
}

.subtaskEntry.red{
    color: #ff9410;
}

.WhiteTheme .subtaskEntry.red{
    color: #007982;
}

.subtaskEntry.green{
    color: #50faa5;
}

.WhiteTheme .subtaskEntry.green{
    color: #007982;
}

.subtaskEntry.yellow{
    color: gold;
}

.WhiteTheme .subtaskEntry.yellow{
    color: #007982;
}

.subtaskEntry.blue{
    color: blue;
}

.WhiteTheme .subtaskEntry.blue{
    color: #007982;
}

.subtaskEntry.purple{
    color: purple;
}

.WhiteTheme .subtaskEntry.purple{
    color: #007982;
}

.workingHighlight{
    padding-left: 2px;
    padding-right: 2px
}

.timerStatusIconL{
    font-size: 18pt;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}

.timerStatusIconR{
    font-size: 18pt;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.BlinkingTimer {
    -webkit-animation: blink 5s infinite both;
            animation: blink 5s infinite both;
}

.BottomRightPanel{
    flex-direction: row;
    display: flex;
    padding-bottom: 8px;
    padding-top: 2.5px;
}

.BottomRightPanelColumn{
    flex-direction: column;
    display: flex;
    flex: 1;
    text-align: center;
}

.BottomRightPanelTitle{
    text-transform: uppercase;
    font-size: 10pt;
    height: 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.BottomRightPanelDesc{
    font-size: 12pt;
    height: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AllTaskHomeDiv{
    margin-top: 6px;
}

.ElapsedMinutesOfCurrentSubSub{
    color: gold;
}

.AppRootYellow .ElapsedMinutesOfCurrentSubSub{
    color: white;
}

.WhiteTheme .ElapsedMinutesOfCurrentSubSub{
    color: #007982;
}

.NegativeHighlight{
    color: #dc3545;
}

.AppRootYellow .NegativeHighlight{
    color: #dc3545;
}

.TaskCountSpan{
    background: rgba(0, 158, 158, 0.5);
    color: #fff;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 20px;
    margin-left: 3px;
    cursor: pointer;
}

.AppRootYellow .TaskCountSpan{
    background: rgba(177, 181, 179, 0.5);
}

.WhiteTheme .TaskCountSpan{
    background-color: #007982;
    color: #fff;
}

.ExpiryDateSub{
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 50px;
}

.ExpiryDateRed{
    color: #ff9410;
}

.WhiteTheme .ExpiryDateRed{
    color: rgba(220, 53, 69);
}

.ExpiryDateYellow{
    color: gold;
}

.WhiteTheme .ExpiryDateYellow{
    color: rgba(158, 108, 0);
}

.ExpiryDateGreen{
    color: #50faa5;
}

.WhiteTheme .ExpiryDateGreen{
    color: #007982;
}

.BottomRightExpiryDate{
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 3px;
}

.WhiteTheme .btn-dark{
    background-color: #e3e3e3;
    color: black;
    border: solid 1px #cfcfcf;
}

.WhiteTheme .btn-success{
    background-color: #007982;
    border: none;
}

.progress-green .progress-bar{
    background-color: #50faa5;
    border-radius: 3px;
}

.WhiteTheme .progress-green .progress-bar{
    background-color: #00b75b;
}

.progress-yellow .progress-bar{
    background-color: gold;
    border-radius: 3px;
}

.WhiteTheme .progress-yellow .progress-bar{
    background-color: #edba01;
}

.progress-red .progress-bar{
    background-color: #ff9410;
    border-radius: 3px;
}

.WhiteTheme .progress-red .progress-bar{
    background-color: #dc3545;
}

.timer-green {
    color: #50faa5;
}

.WhiteTheme .timer-green {
    color: #00b75b;
}

.timer-yellow {
    color: gold;
}

.WhiteTheme .timer-yellow {
    color: #d59e26;
}

.timer-red {
    color: #ff9410;
}

.flag-red {
    color: #dc3545;
}

.WhiteTheme .flag-red{
    color: #dc3545;
}

.WhiteTheme .timer-red {
    color: #dc3545;
}

.paymeeco{
    color: cyan
}

.paymeeco.legend{
    color: cyan;
}

.AppRootYellow .paymeeco{
    color: #fff;
}

.AppRootYellow .paymeeco.legend{
    color: #fff;
}

.WhiteTheme .paymeeco{
    color: teal;
}

.countUpNotify{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.countUpNotify>div{
    background-color: cyan;
    color: #000;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.WhiteTheme .countUpNotify>div{
    background-color: #e3e3e3;
}

.NoTaskWarn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
}
.NoTaskWarn>div{
    background-color: rgba(255, 0, 0, 0.5);
    color: #FFF;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
}

.WhiteTheme .NoTaskWarn>div{
    background-color: #dc3545;
}

.TimerAdjustedWarn{
    background-color: rgba(211, 179, 0, 0.851);
    color: #000;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 2px;
    cursor: pointer;
}

.WhiteTheme .TimerAdjustedWarn{
    background-color: #ffca2c;
}

.WarningAreas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.WarningAreas:last-child{
    margin-left: 0px;
}

.TodosAndHabits{
    border-radius: 5px;
    overflow: hidden;
}

.TodosAndHabits>div>div:last-child{
    margin-right: 0px;
    padding-right: 0px;
}

.operationInfo{
    margin-top: 10px;
}

.TimerCommandGauges{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 10px;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.TimerCommandGauges .GaugeCell{
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
}

.TagAreaOfSubTask{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 5px;
    color: cyan;
    opacity: 0.75;
    margin-top: 6px;
    margin-bottom: 3px;
}

.AppRootYellow .TagAreaOfSubTask{
    color: #fff;
}

.WhiteTheme .TagAreaOfSubTask{
    color: black;
    opacity: 1;
}

.tag{
    border-radius: 3px;
    margin-right: 1px;
    padding-left: 1px;
    padding-right: 1px;
    color: cyan;
    text-transform: uppercase;
}

.AppRootYellow .tag{
    color: #fff;
}

.WhiteTheme .tag{
    color: #007982;
}

.TimeRemainingByTag{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    flex-wrap: wrap;
}

.TimeRemainingByTag>.TimeRemainingByTagEntry{
    margin-left: 5px;
    margin-right: 5px;
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.TimeRemainingByTag>.TimeRemainingByTagEntry>.tag{
    margin-right: 3px;
}

.procast_tasks_days_rems{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: cyan;
}

.AppRootYellow .procast_tasks_days_rems{
    color: #fff;
}

.procast_tasks_days_rems>span{
    padding-left: 4px;
}

.WhiteTheme .procast_tasks_days_rems{
    color: #007982
}

.ClockInHomeScreen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ClockWeatherInfo{
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

.ClockWeatherInfo .ClockWeatherInfoRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 4px;
}

.ClockWeatherInfo .ClockWeatherInfoRow .ClockWeatherInfoCell{
    display: flex;
    flex: 1;
    flex-direction: row;
    
}

.ClockWeatherInfo .ClockWeatherInfoRow .ClockWeatherInfoCell .ClockWeatherInfoCellIcon{
    width: 40px;
    font-size: 12pt;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.ClockWeatherInfo .ClockWeatherInfoRow .ClockWeatherInfoCell .ClockWeatherInfoCellText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ClockWeatherInfo .ClockWeatherInfoRow .ClockWeatherInfoCell .ClockWeatherInfoCellTitle{
    font-weight: bold;
}

.ClockWeatherInfo .ClockWeatherInfoRow .ClockWeatherInfoCell .ClockWeatherInfoCellDesc{
}

.RightPanel{
    background-color: rgba(0, 0, 0, 0.15);
    min-height: 100%;
    margin-top: 30px;
}

.WhiteTheme .RightPanel{
    background-color: rgba(255, 255, 255, 0.4);
}

.TimerDashboard{
    margin-left: 8px;
    margin-right: 8px;
}

.TimerDashboard Button.btn-dark{
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
}

.WhiteTheme .TimerDashboard Button.btn-dark{
    background-color: rgba(255, 255, 255, 0.15);
}

.TimerDashboard Button.btn-dark:hover{
    background-color: rgba(255, 255, 255, 0.35);
}

.WhiteTheme .TimerDashboard Button.btn-dark:hover{
    background-color: rgba(255, 255, 255, 0.55);
    color: black;
}

.TimerDashboard Button.btn-primary{
    background-color: rgba(45, 119, 255, 0.15);
}

.WhiteTheme .TimerDashboard Button.btn-primary{
    background-color: rgba(45, 119, 255, 0.7);
}

.TimerDashboard Button.btn-primary:hover{
    background-color: rgba(45, 119, 255, 0.35);
}

.WhiteTheme .TimerDashboard Button.btn-primary:hover{
    background-color: rgba(45, 119, 255, 0.8);
}

.StampSwitcher Select{
    background-color: rgba(0, 0, 0, 0.15);
    color: white;
}

.WhiteTheme .StampSwitcher Select{
    color: black;
    background-color: white;
}

.TimerControlPrimaries{
    margin-top: 5px;
}

.AppRoot Button.btn-primary{
    background-color: rgba(45, 119, 255, 0.5);
    border: none;
}

.WhiteTheme Button.btn-primary{
    background-color: rgba(45, 119, 255, 1);
}

.AppRoot Button.btn-primary:hover{
    background-color: rgba(45, 119, 255, 0.8);
}

.AppRoot Button.btn-success{
    background-color: rgba(40, 167, 69, 0.5);
    border: none;
}

.WhiteTheme Button.btn-success{
    background-color: rgba(40, 167, 69, 0.7);
}

.AppRoot Button.btn-success:hover{
    background-color: rgba(40, 167, 69, 0.8);
}

.AppRoot Button.btn-danger{
    background-color: rgba(220, 53, 69, 0.5);
    border: none;
}

.WhiteTheme Button.btn-danger{
    background-color: rgba(220, 53, 69, 0.7);
}

.AppRoot Button.btn-danger:hover{
    background-color: rgba(220, 53, 69, 0.8);
}

.AppRoot Button.btn-warning{
    background-color: rgba(255, 193, 7, 0.5);
    border: none;
}

.WhiteTheme Button.btn-warning{
    background-color: rgba(255, 193, 7, 1);
}

.AppRoot Button.btn-warning:hover{
    background-color: rgba(255, 193, 7, 0.8);
}


.CalendarDayView{
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100vh - 30px);
}

.CalendarDayView::-webkit-scrollbar{
    display: none;
}

.ClockCorner{
    cursor: pointer;
}

.energy-buttons{
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
}

.EnergyButtonsArea{
    display: flex;
    flex: 1 0 auto;
    margin-top: 5px;
}

.energy-buttons .btn-dark{
    background-color: rgba(255, 255, 255, 0.16);
    border: none;
}

.energy-buttons .btn-dark:hover{
    background-color: rgba(255, 255, 255, 0.35);
}

.ProfilePicWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.WhiteTheme .ProfilePicWrapper{
    background: none;
}

.ProfilePicWrapper .username{
    display: flex;
    font-weight: bold;
    margin-left: 10px;
    align-items: center;
}

.shimmer {
    display: inline-block;
    color: rgba(255, 255, 255, 0.1);
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.7) 50%,
        rgba(255, 255, 255, 0.3) 100%
    );
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shimmer 3s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}