.TextBarToDo{
    display: block;
    width: 100%;
    padding-right: 2px;
}

.SquareTodo{
    width: 120px;
    border: 1px gold solid;
    height: 100px;
    margin: 12px;
    text-align: center;
    padding: 2px;
    border-radius: 10px;
    
}

.TitleTodo{
    background-color: rgba(45, 119, 255, 0.4);
    color: white;
    position: relative;
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
}

.TitleTodo::before{
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer1 4s infinite;
}

@keyframes shimmer1 {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(200%);
    }
}

.AppRootYellow .TitleTodo{
    background-color: rgba(126, 126, 126, 0.5);
    color: #fff;
}

.WhiteTheme .TitleTodo{
    background-color: #007982;
    color: white;
}

.DetailsTodo{
    border-left: 1px rgba(45, 119, 255, 0.4) solid;
    border-right: 1px rgba(45, 119, 255, 0.4) solid;
    border-bottom: 1px rgba(45, 119, 255, 0.4) solid;
    padding: 2px;
    margin: 0 auto;
    padding-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.AppRootYellow .DetailsTodo{
    border-left: 1px rgba(177, 181, 179, 0.5) solid;
    border-right: 1px rgba(177, 181, 179, 0.5) solid;
    border-bottom: 1px rgba(177, 181, 179, 0.5) solid;
}