.MyFormContainer{
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.QAFormRow{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.QAFormRow .QAFormQuestion{
    font-weight: bold;
    margin-bottom: 10px;
}

.QAFormSelector{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 20px;
}

.QAPastResponses{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.bordered-table {
    border: 1px solid #fff;
    border-collapse: collapse;
}

.bordered-table th, .bordered-table td {
    border: 1px solid #fff;
    padding: 5px;
}
