.SplashScreen{
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* Needed to position the pseudo-element */
    overflow: hidden; /* To contain the blur within the container */
    z-index: 1; /* Ensures content is above the pseudo-element */
    width: 100%;
    max-width: 450px;
}

.SplashScreen .SplashScreenWrapper{
    backdrop-filter: blur(10px);
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SplashClock {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 450px;
    cursor: pointer;
}

.SplashClock .Clock {
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.SplashClock .Clock .Date{
    font-size: 1.2em;
    color: #fff;
}

.SplashClock .Clock1 .Time {
    font-size: 5em;
    font-weight: bold;
}

.SplashClock .Time .Hour{
    color: cyan
}

.SplashClock .Time .Minute{
    color: #fff
}

.SplashClock .Clock2 .Time {
    font-size: 4em;
    font-weight: bold;
}

.SplashClock .Clock3 .Time {
    font-size: 3em;
    font-weight: bold;
}

.SplashClock .Clock .City{
    font-size: 1.2em;
    color: #fff;
}

.UserInfo {
    position: fixed;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: rgba(82, 209, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
    cursor: pointer;

    padding-right: 24px;
}

.ProfilePic{
    display: flex;
    flex-direction: row;
}

.ProfilePic .balance{
    margin-left: 12px;
}

.ProfilePreview{
    position: fixed;
    top: calc(50vh - 160px);
    left: calc(50vw - 160px);
    z-index: 10;
    display: flex;
    flex-direction: column;
}

.ProfileCaption{
    background-color: rgba(82, 209, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 10px;
    margin-top: -40px;
}

.SplashNavs {
    display: flex;
    align-items: center;
}

.NavItem {
    display: inline-block; /* Display nav items in a row */
    margin-right: 8px; /* Add some space between items and the dot */
    cursor: pointer;
}

/* Add a dot after each nav item except the last one */
.NavItem:not(:last-child)::after {
    content: " • "; /* This is the dot */
    display: inline-block;
    margin-left: 8px;
}

.splash-lock {
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 12px;
    padding-right: 12px;
    background: rgba(255, 255, 255, 0.3);
    padding-top: 2px;
    padding-bottom: 4px;
    border-radius: 60px;
}

.AppRootNoBg .splash-lock{
    background: none;
}

.splash-back{
    cursor: pointer;
    position: fixed;
    top: 12px;
    left: 12px;
}

/* Fireworks */
.Fireworks{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.SplashMainContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.CelebrationMessage{
    
}