.LargeWeatherDiv{
    display: flex;
    flex-direction: column;
}

.VSpacing{
    display: flex;
    flex: 1 1 auto;
}

.LargeWeatherDiv .WeatherAtAGlance{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LargeWeatherDiv .BigTemperature{
    font-size: 2.5em;
    font-weight: bold;
}

.LargeWeatherDiv .WeatherAtAGlanceDesc{
    font-size: 1.25em;
    margin-top: -10px;
    font-weight: bold;
}