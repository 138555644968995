.BodyEdit{
    padding: 24px;
}

.ScheduleEditor{
    margin-top: 24px;
}

.ScheduleEditor .ScheduleItem{
    display: flex;
    flex-direction: column;
    background-color: #ffffff2e;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
}

.ScheduleEditor .ButtonRow{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.ScheduleItem .ItemItem{
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}

.ScheduleItem .NextEvaGreen{
    color: #00ff00;
}

.ScheduleItem .ItemItem div {
    flex: 1 1 0;
}

.ScheduleItem .ItemWarn{
    color: #ff0000;
}